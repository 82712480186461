<template>
  <div id="app">
    <router-view />

    <transition name="fade">
      <Spinner :start="spin" />
    </transition>
  </div>
</template>

<script>
import Spinner from "./components/Spinner.vue";

export default {
  name: "app",
  components: { Spinner },
  data() {
    return {
      spin: true,
    };
  },
  methods: {
    quitSpin() {
      setTimeout(() => (this.spin = false), 3000);
    },
  },
  mounted() {
    this.quitSpin();
  },
};
</script>

<style>
body {
  color: #5a5c63;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
.mesos,
.portillo {
  background-image: url(./assets/fondo.jpg);
}
.portillo {
  background-color: white;
}
body {
  background-image: url(./assets/fondo.jpg);
}
.btn-contratar {
  margin-bottom: 50px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.btn-info{
  color:#fff;background-color:#cd9834!important;
  border-color: #cd9834!important;
  }
.btn-info.focus,.btn-info:focus,.btn-info:hover{
  color:#fff;background-color:#000000!important;
  }
.btn-info.focus,.btn-info:focus{
  box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
  }
.btn-info.disabled,.btn-info:disabled{
  color:#fff;background-color:#17a2b8;border-color:#17a2b8
}.btn-info:not(:disabled):not(.disabled).active,.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle{
  color:#fff;
  background-color:#117a8b;

  }
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(Router)
const routes = [{
    path: '/',
    name: 'home',
    component: require('../views/Home.vue').default

},
{
    path: '/:token',
    name: 'inicio',
    component: Home
},
{
    path: '/error',
    name: 'error',
    component: require('../views/Error.vue').default
}]
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router




<template>
   <div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100">
				<form class="login100-form validate-form">
                    <span class="login100-form-title p-b-48">
						<img src="@/assets/img/logoBoss.png">
					</span>
					<span class="login100-form-title p-b-26">
						¡Ups! Hemos tenido un problema
					</span>
                    <span class="login50-form-title p-t-10 p-b-26">
					{{this.$route.params.mensaje}}
					</span>
					

					

					

					
				</form>
			</div>
		</div>
        
	</div>
	

	
</template>

<script>

    export default {
        
    }
</script>

<style  scoped>
@import '../assets/css/main.css';
@import '../assets/css/bootstrap.css';
@import '../assets/css/font-awesome.css';
@import '../assets/css/iconic/css/material-design-iconic-font.min.css';
@import '../assets/css/util.css';
</style>
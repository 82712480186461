<template>
    
	<div class="limiter">
      
		<div class="container-login100">
          
			<div class="wrap-login100">
                <span class="login100-form-title p-b-48">
						<img src="~@/assets/img/logoBoss.png">
					</span>
				<form class="login100-form validate-form" v-on:submit.prevent="validaPass()" id="formularioPass">
                    
					<span class="login100-form-title p-b-26">
						Hola! {{this.userData.usuario}} 
					</span>
                    <span class="login50-form-title p-t-10 p-b-26">
					Aquí podrás reestablecer tu contraseña de acceso a Boss
					</span>
                    <span class="login25-form-title p-t-10 p-b-10">
					<ul class="lista">
                        <li>La contraseña debe tener al menos una letra y un número</li>
                        <li>La contraseña debe tener mínimo 6 caracteres</li>
                    </ul>
					</span>
					
                    <div class="cont-error">
					<div class="alert alert-danger" id="mensajeError"  role="alert">{{this.msjErrorForm}}</div>
                    </div>
					<div class="wrap-input100 validate-input" data-validate="Enter password">
                        
						<span class="btn-show-pass" v-on:click="verificarPass" v-if="!ojo" >
							<i class="zmdi zmdi-eye-off"></i>
                            
						</span>
                        <span class="btn-show-pass" v-on:click="verificarPass" v-if="ojo" >
                            <i  class="zmdi zmdi-eye"></i>
						</span>
						<input class="input100" v-model="userData.passUno"  ref="newPass" v-if="ojo"  type="password" name="pass">
                        <input class="input100" v-model="userData.passUno"  ref="newPass" v-if="!ojo" type="text" name="pass">
						<span class="focus-input100" data-placeholder="Nueva Contraseña"></span>
					</div>

                    <div class="wrap-input100 validate-input" data-validate="Enter password">
                        
						<span class="btn-show-pass" v-on:click="verificarPassRe" v-if="!ojoRe" >
							<i class="zmdi zmdi-eye-off"></i>
                            
						</span>
                        <span class="btn-show-pass" v-on:click="verificarPassRe" v-if="ojoRe" >
                            <i  class="zmdi zmdi-eye"></i>
						</span>
						<input class="input100" v-model="userData.passDos" v-if="ojoRe" type="password" name="pass">
                        <input class="input100" v-model="userData.passDos" v-if="!ojoRe" type="text" name="pass">
						<span class="focus-input100" data-placeholder="Repite Contraseña"></span>
					</div>

					<div class="container-login100-form-btn">
						<div class="wrap-login100-form-btn">
							<div class="login100-form-bgbtn"></div>
							<button class="login100-form-btn">
								Cambiar Clave
							</button>
						</div>
					</div>

					
				</form>
                <div id="seguridadDiv">
                    <span class="login50-form-title p-t-10 p-b-26">
					{{this.userData.usuario}}  su contraseña fue reestablecida y será direccionado a Boss dentro de poco. <br>  <br> ¡Recomendamos cerrar otras ventanas de Boss abiertas!
					</span>
                </div>
			</div>
		</div>
       
        <Spinner :start="spin" />
        
	</div>
	

	
	

</template>
<script>
import Spinner from "@/components/Spinner.vue";
import axios from "axios";
export default {
  name: "app",
  components: { Spinner },
  data() {
    return {
        spin: true,
        ojo:true,
        ojoRe:true,
        valNew:false,
        msjErrorForm: null,
        valData: {
            tknKong: null,
            tnkUrl: null,
        },
        userData: {
            usuario: null,
            linkboss: null,
            passUno: null,
            passDos: null,
        }
        

    }; 
  },mounted() {
      this.ocultarDiv();
      this.obtenerToken();
       
  },    
  methods:{
      verificarPass: function(){
          if(this.ojo){
             this.ojo = false 
            
          }else{
              this.ojo = true  

          }
      },
      verificarPassRe: function(){
          if(this.ojoRe){
             this.ojoRe = false 
          }else{
              this.ojoRe = true  
          }
      },
      obtenerToken(){
          console.log("1")
          this.valData.tnkUrl = this.$route.params.token;
          console.log("jwt ruta :"+this.valData.tnkUrl);
          console.log("url de confirmacion: "+this.$urlTknKng);
        axios
        .post(this.$urlTknKng,{
            "client_id":this.$tknKngClienteId,
            "client_secret": this.$tknKngClientSecret,
            "grant_type": this.$tnkKngGrantType,
            "provision_key": this.$tnkKngProvKey,
            "authenticated_userid": this.$tnkKngAuthUserId,
            "scope": "read"
        })
        .then((response) => {
            this.valData.tknKong = response.data.access_token;
            console.log("Tokn Kong : "+this.valData.tknKong)
            this.validaToken(this.valData.tknKong);
           
        })
        .catch((e) => {
          console.log("error :" + e);
          this.$router.push({
            name: "error",
             params: {
                mensaje: "Falló la comunicación en la generación de token, favor contactar a soporte" 
              },
          });
        });



      },
      obtenerTokenCambioPass(){
          this.valData.tnkUrl = this.$route.params.token;
        axios
        .post(this.$urlTknKng,{
            "client_id":this.$tknKngClienteId,
            "client_secret": this.$tknKngClientSecret,
            "grant_type": this.$tnkKngGrantType,
            "provision_key": this.$tnkKngProvKey,
            "authenticated_userid": this.$tnkKngAuthUserId,
            "scope": "read"
        })
        .then((response) => {
            this.valData.tknKong = response.data.access_token;
            console.log("Tokn Kong : "+this.valData.tknKong)
           
        })
        .catch((e) => {
          console.log("error :" + e);
          this.$router.push({
            name: "error",
             params: {
                mensaje: "Falló la comunicación en la generación de token, favor contactar a soporte" 
              },
          });
        });
      },
      validaToken(tokenKong){
          console.log("2")
           console.log("Kong en valida  jwt :"+tokenKong);

		console.log("Validar token ruta :"+this.$route.params.token);
          axios
          .post(this.$urlValidaTkn,{
                'token' : this.$route.params.token
          },{
            headers: {
              Authorization: "Bearer " + tokenKong,
            },
          }).then((response) =>{
              if(response.data.data.indError == 'N'){
				console.log("entra en N")
                  this.userData.usuario = response.data.data.usuario
                  this.userData.linkboss = response.data.data.linkBoss
                  this.spin = false
              }else{
				console.log("entra en else")
                this.$router.push({
                    name: "error",
                    params: {
                        mensaje: response.data.data.mensaje
                    },
                }); 
              }
          }).catch((e)=>{
            this.$router.push({
                name: "error",
                params: {
                    mensaje: "No pudimos valida el link, favor contactar a soporte :"+e
                },
            });
          });
      },
      validaPass(){
          if(!this.userData.passUno || !this.userData.passDos){
              console.log("ingrese texto");
              var msj ='Debe ingresar su nueva contraseña';
              this.mansajeErrorForm(msj);
              
          }else{
              
              if(this.userData.passUno ==  this.userData.passDos){
                 
                  
                 // var textop = this.userData.passUno;
                 console.log("largo :"+this.userData.passUno.length);
                   if(this.userData.passUno.length >=6){

                   
                        if(/.*[a-zA-Z].*/.test(this.userData.passUno) && /.*[0-9].*/.test(this.userData.passUno) ){
                        
                            this.obtenerTokenCambioPass();
                             console.log("Kong  cambio pass:"+this.valData.tnkUrl);
                            this.solicitudCambioPass(this.valData.tknKong);

                            


                        }else{
                            msj ='La contraseña debe de tener al menos una letra y un número';
                            this.mansajeErrorForm(msj);
                        }
                    }else{
                        msj ='La contraseña debe tener una logitud mínima de 6 caracteres';
                            this.mansajeErrorForm(msj);
                    }
              }else{
                  msj ='Las contraseñas no coinciden';
                this.mansajeErrorForm(msj);
              }
          }
      },
      ocultarDiv(){
          $('#mensajeError').hide();
          $('#seguridadDiv').hide();
      },
      mansajeErrorForm(msj){
        this.msjErrorForm = msj;
        $('#mensajeError').fadeIn(1000,function(){})   
        $('#mensajeError').delay(3000).fadeOut(1000,function(){
            this.msjErrorForm = null;
        })
      },
      solicitudCambioPass(tknKong){
          console.log("3")
           console.log("Kong solicitudCambioPass:"+tknKong);
          axios
          .post(this.$urlSolCambioPass,{
                'usuario' : this.userData.usuario,
                'password' : this.userData.passUno,
                'jwt' : this.valData.tnkUrl,
          },{
            headers: {
              Authorization: "Bearer " + tknKong,
            },
          }).then((response) =>{
              if(response.data.data.estado == 'OK'){
                $("#formularioPass").delay(10).fadeOut(1000,function(){});
                $('#seguridadDiv').delay(1000).fadeIn(1000,function(){});
               // setTimeout(function() { this.$router.push(this.userData.linkboss)}, 5000);location.href
               var boss = this.userData.linkboss;
               console.log("Ruta : "+boss);
                this.direccionamientoBoss(boss)
                
              }else{
                this.$router.push({
                    name: "error",
                    params: {
                        mensaje: response.data.data.mensaje
                    },
                }); 
              }
          }).catch((e)=>{
            this.$router.push({
                name: "error",
                params: {
                    mensaje: "No pudimos realizar en cambio de contraseña, favo contactar a soporte - "+e
                },
            });
          })
      },
      direccionamientoBoss(boss){
            console.log("4")
            setTimeout(function() { 
                    location.href = 'http://'+boss;
                }, 10000);


      }
  }
  
}
const $ = require('jquery')
window.$ = $
$( document ).ready(function() {
 $('.input100').each(function(){
        $(this).on('blur', function(){
            if($(this).val().trim() != "") {
                $(this).addClass('has-val');
            }
            else {
                $(this).removeClass('has-val');
            }
        })    
    });
    });


</script>
<style >
@import '../assets/css/main.css';
@import '../assets/css/bootstrap.css';
@import '../assets/css/font-awesome.css';
@import '../assets/css/iconic/css/material-design-iconic-font.min.css';
@import '../assets/css/util.css';
#mensajeError{
    
    top:20px;
}
.cont-error{
    height: 100px;
}
.login25-form-title{
    display: block;
    font-family: 'Poppins-Medium';
    font-size: 11px;
    color: #333;
    line-height: 1.2;
    text-align: center;
}
.lista li{
    list-style-type: disc;
    text-align: start;
}
.p-b-10{
    padding-bottom: 10px;
}

</style>
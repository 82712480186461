import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap';
import './assets/css/app.css';
import './assets/css/util.css';
import './assets/css/main.css';

//Generar Token Kong test
Vue.prototype.$urlTknKng = 'https://api2.test.mesos.cl/apiCambiaPasswd/oauth2/token'; 
Vue.prototype.$tknKngClienteId = 'xb2X0ayOgGqgcey6shGGksQpVPHG5zaM';
Vue.prototype.$tknKngClientSecret = '5zefb3FSgkzTlJf0ObXKIJ6zo8SUMDnY';
Vue.prototype.$tnkKngGrantType = 'client_credentials';
Vue.prototype.$tnkKngProvKey = '2Q3Bn4A609W2310M9OncV6Rg5YZTpFzi';
Vue.prototype.$tnkKngAuthUserId = 'apisftp';

//Generar Token kong qa
//Vue.prototype.$urlTknKng = 'https://api-qa.pvdigital.cl/apiCambiaPasswd/oauth2/token'; 
//Vue.prototype.$tknKngClienteId = 'L1eKzsQ7lUB3HxLNWCh7CFVJgcrAmpW8';
//Vue.prototype.$tknKngClientSecret = 'mMJ6V37qXYBKfzPYAZkPmsbRLV6r8iXu';
//Vue.prototype.$tnkKngGrantType = 'client_credentials';
//Vue.prototype.$tnkKngProvKey = '2Q3Bn4A609W2310M9OncV6Rg5YZTpFzi';
//Vue.prototype.$tnkKngAuthUserId = 'apisftp';

//Validar Token Url test
Vue.prototype.$urlValidaTkn = 'https://api2.test.mesos.cl/apiCambiaPasswd/login';

//Validar Token Url qa
//Vue.prototype.$urlValidaTkn = 'https://api-qa.pvdigital.cl/apiCambiaPasswd/login';

//Solicitud Cambio Pass test
Vue.prototype.$urlSolCambioPass = 'https://api2.test.mesos.cl/apiCambiaPasswd/actualizarPasswd';

//Solicitud Cambio Pass qa
//Vue.prototype.$urlSolCambioPass = 'https://api-qa.pvdigital.cl/apiCambiaPasswd/actualizarPasswd';


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
